html {
  --color-primary-50: #EDF9FAFF;
  --color-primary-100: #C9ECF1FF;
  --color-primary-300: #90D9E3FF;
  --color-primary-500: #17BED0FF;
  --color-primary-600: #0E8C9AFF;
  --color-primary-700: #065E67FF;
  --color-primary-800: #023339FF;
  --color-primary-900: #000D10FF;
  --color-grey-100: #FCFCFCFF;
  --color-grey-200: #F4F5F6FF;
  --color-grey-300: #EFF0F1FF;
  --color-grey-400: #E4E5E7FF;
  --color-grey-500: #C6CACEFF;
  --color-grey-600: #8C959DFF;
  --color-grey-700: #656D76FF;
  --color-grey-800: #494F55FF;
  --color-grey-900: #282C2FFF;
  --color-success: #55B938;
  --color-warning-200: #FFF4D5;
  --color-warning-500: #FFD865;
  --color-neutral-500: #C6CACE;
  --color-functional-positive100: #EFF9EBFF;
  --color-functional-positive200: #CFEBC2FF;
  --color-functional-positive500: #5CB900FF;
  --color-functional-warning100: #FFFBF1FF;
  --color-functional-warning200: #FFF4D5FF;
  --color-functional-warning500: #FFD865FF;
  --color-functional-danger100: #FFEEEBFF;
  --color-functional-danger200: #FFCBC5FF;
  --color-functional-danger500: #F73C3CFF;
  --color-base-white: #FFFFFFFF;
  --color-base-black: #000000FF;
  --color-icon-menu: #7A8D8F;
  --surface-skeleton: #EFF0F1;
  --spaces-0: 4px;
  --spaces-1: 8px;
  --spaces-2: 16px;
  --spaces-3: 24px;
  --spaces-4: 32px;
  --spaces-5: 40px;
  --spaces-6: 48px;
  --spaces-7: 56px;
  --spaces-8: 64px;
  --spaces-9: 72px;
  --spaces-10: 80px;
  --spaces-12: 96px;
  --spaces-14: 112px;
  --spaces-16: 128px;
  --spaces-20: 160px;
  --radius-xs: 4px;
  --radius-sm: 8px;
  --radius-md: 16px;
  --radius-lg: 24px;
  --radius-xl: 32px;
  --radius-2xl: 40px;
  --radius-full-g: 9999px;
  --spacing-none: 0px;
  --border-size: 1px;
  --button-secondary-background-pressed: #FFFFFFFF;
  --button-positive-background-default: #FFFFFFFF;
  --button-positive-label-default: #FFFFFFFF;
  --button-positive-background-pressed: #FFFFFFFF;
  --button-positive-label-pressed: #FFFFFFFF;
  --button-positive-background-disabled: #FFFFFFFF;
  --button-positive-label-disabled: #FFFFFFFF;
  --button-danger-background-default: #FFFFFFFF;
  --button-danger-label-default: #FFFFFFFF;
  --button-danger-background-pressed: #FFFFFFFF;
  --button-danger-label-pressed: #FFFFFFFF;
  --button-danger-background-disabled: #FFFFFFFF;
  --button-danger-label-disabled: #FFFFFFFF;
  --button-secondary-label-pressed: #FFFFFFFF;
  --button-secondary-background-disabled: #FFFFFFFF;
  --button-secondary-label-disabled: #FFFFFFFF;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;

  // Right content vars:
  --right-content-border-width: 1px;
  --right-content-width: 400px;
  --items-info-content-width: 0px;
  --upload-content-width: 0px;
  --items-info-content-right-mobile: -100%;

  // Icons:
  --icon-extra-small: 8px;
  --icon-small: 14px;
  --icon-base: 16px;
  --icon-medium: 18px;
  --icon-big: 24px;
  --icon-color-link: var(--color-primary-500);
  --icon-color-light: var(--color-icon-menu);
  --icon-color-lighter: var(--color-grey-500);
  --icon-color-dark: var(--color-grey-600);

  // Aliases:
  --text-primary: var(--color-grey-900);
  --text-secondary: var(--color-grey-600);
  --text-positive: var(--color-functional-positive500);
  --text-warning: var(--color-functional-warning500);
  --text-danger: var(--color-functional-danger500);
  --text-link: var(--color-primary-500);
  --surface-primary: var(--color-grey-200);
  --surface-secondary: var(--color-grey-100);
  --surface-brand: var(--color-primary-300);
  --surface-selected: var(--color-primary-50);
  --surface-hover: var(--color-grey-200);
  --surface-hover-dark: var(--color-grey-300);
  --surface-tooltip: var(--color-grey-800);
  --text-brand: var(--color-primary-900);
  --border-primary: var(--color-grey-400);
  --border-secondary: var(--color-grey-300);
  --border-warning: var(--color-warning-500);
  --divider-primary: var(--color-grey-400);
  --divider-secondary: var(--color-grey-300);
  --spacing-xs: var(--spaces-0);
  --spacing-sm: var(--spaces-1);
  --spacing-md: var(--spaces-2);
  --spacing-lg: var(--spaces-3);
  --spacing-xl: var(--spaces-4);
  --spacing-2xl: var(--spaces-5);
  --spacing-3xl: var(--spaces-9);
  --radius-minimum: var(--radius-xs);
  --radius-rounded: var(--radius-md);
  --radius-full: var(--radius-full-g);
  --button-primary-background-default: var(--color-primary-500);
  --button-primary-label-default: var(--color-base-white);
  --button-primary-background-pressed: var(--color-primary-600);
  --button-primary-label-pressed: var(--color-base-white);
  --button-primary-background-disabled: var(--color-grey-400);
  --button-primary-label-disabled: var(--color-grey-500);
  --button-secondary-background-default: var(--color-base-white);
  --button-secondary-label-default: var(--text-primary);
  --radius-button: var(--radius-xs);
  --button-secondary-border-default: var(--text-primary);
  --button-secondary-border-pressed: var(--text-primary);
  --button-secondary-border-disabled: var(--text-primary);
  --button-tetriary-background-default: var(--color-primary-500);
  --button-tetriary-label-default: var(--color-base-white);
  --button-tetriary-background-pressed: var(--color-primary-600);
  --button-tetriary-label-pressed: var(--color-base-white);
  --button-tetriary-background-disabled: var(--color-grey-400);
  --button-tetriary-label-disabled: var(--color-grey-500);

  --small-font-size: var(--font-size-11);
  --base-font-size: var(--font-size-14);
  --medium-font-size: var(--font-size-16);

  --right-icon-full-width: 28px;

  height: 100%;
  scrollbar-width: var(-spacing-xs);
  scrollbar-color: var(--text-secondary) transparent;

  .extraSmallIcon {
    font-size: var(--icon-extra-small);
    line-height: calc(var(--icon-extra-small) + 1px);
  }

  .smallIcon {
    font-size: var(--icon-small);
    line-height: calc(var(--icon-small) + 1px);
  }

  .baseIcon {
    font-size: var(--icon-base);
    line-height: calc(var(--icon-base) + 1px);
  }

  .mediumIcon {
    font-size: var(--icon-medium);
    line-height: calc(var(--icon-medium) + 1px);
  }

  .bigIcon {
    font-size: var(--icon-big);
    line-height: calc(var(--icon-big) + 1px);
  }

  .linkColorIcon {
    color: var(--icon-color-link);
  }

  .lightColorIcon {
    color: var(--icon-color-light);
  }

  .darkColorIcon {
    color: var(--icon-color-dark);
  }

  body {
    height: 100%;

    .cv-tooltip {
      background: var(--color-base-black);
      font-size: var(--small-font-size);
      line-height: 16px;
      color: var(--color-grey-100);
      padding: var(--spacing-xs) var(--spacing-sm);
      border-radius: var(--radius-minimum);
      z-index: 9999;
      max-width: 300px;
      //max-height: 80px;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      overflow-wrap: break-word;

      &.cv-tooltip-auto-height {
        max-height: none;
      }
    }

    .tooltip-portal {
      z-index: 20000;

      @media (max-width: 500px) {
        width: 275px;
      }
    }

    .page-loader {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 103;
      background-color: rgba(255, 255, 255, .4);
      transition: opacity 300ms ease;

      .loader {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
        border-radius: 100%;
        border: 4px solid #17bed0;
        -webkit-animation: pulsate 1s ease-out;
        -moz-animation: pulsate 1s ease-out;
        -o-animation: pulsate 1s ease-out;
        animation: pulsate 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        opacity: 0;
      }
    }

    .megawrap {
      height: 100%;

      .midContain {
        height: 100%;

        .content {
          height: 100%;
          min-width: auto !important;
          overflow: hidden;
          padding-right: 0;
          background-color: var(--surface-primary);

          .main {
            min-height: auto;
            height: 100%;
            background-color: var(--color-base-white);

            #content-wrapper {
              height: 100%;

              #content-top-bar {
                .promo-sticky-bar {
                  display: flex;
                }

                .trialinfo-bar-holder {
                  .active-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: var(--spacing-sm) var(--spacing-lg);
                    box-sizing: border-box;
                    height: 40px;
                    border-radius: var(--radius-full);
                    border: 1px solid var(--text-primary);
                    a {
                      color: var(--text-primary);
                      text-align: center;
                      font-size: 15px;
                      font-weight: 500;
                      line-height: 24px;
                    }
                  }

                  @media (max-width: 800px) {
                    .active-btn {
                      margin: 0;
                      padding: 0;
                      height: auto;
                      border: none;
                    }
                  }
                }

                min-height: 64px;

                @media only screen and (max-width: 1023px) {
                  min-height: 114px;
                }
              }

              #topbar-container {
                #ribbon-container {
                  display: none;
                  align-self: start;

                  .ribbon-wrapper {
                    @media only screen and (max-width: 1023px) {
                      a {
                        height: auto;
                        transform: translateY(-2px);
                        display: inline-block;
                      }
                    }
                  }
                }

                @media only screen and (min-width: 1024px) {
                  padding-left: var(--spacing-md);
                }
              }

              #content-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: var(--spacing-md);
                height: calc(100% - 64px);

                @media only screen and (max-width: 1023px) {
                  height: calc(100% - 138px);
                  padding-left: 0px;
                }

                #content-grid {
                  width: 100%;
                  min-width: 0;
                  height: 100%;
                  position: relative;

                  & .cnt-mn>#root,
                  & #pcloud-faq-container,
                  & #pcloud-help,
                  & #bonus-steps,
                  & .revisionspage,
                  & .choosenewemail,
                  & .changeemail,
                  & .deactivateaccount,
                  & .audiopage,
                  & .brandlinks,
                  & .buy_crypto,
                  & .unlock_box,
                  & .setup_box,
                  & .unlockspace,
                  & .publicpage,
                  & .links,
                  & .publinkContent,
                  & .daylylinkstats,
                  & .cnt-center,
                  & .cnt-left,
                  & .business-invite,
                  & .invite-folder,
                  & .family-invite,
                  & .feedback,
                  & .splash,
                  & .mob_wrap,
                  & .histpage,
                  & .business-account,
                  & .billing-page,
                  & .b-users-wrapper,
                  & .b-teams-wrapper,
                  & .b-user-wrapper,
                  & .b_sharespage {
                    overflow-y: auto;
                    height: 100%;
                  }

                  & .bookmarks,
                  & .filerequests-wrapper,
                  & .filerequests-list {
                    height: 100%;
                  }

                  & .tab-settings .tabcontent {
                    overflow-y: auto;
                    height: calc(100vh - 150px);
                    width: 100%;
                  }

                  & .sharetabs {
                    height: 100%;

                    .tabcontent {
                      display: flex;
                      flex-direction: column;
                      flex: 1 1 auto;

                      .gridlist {
                        height: 100%;
                        overflow: auto;
                      }
                    }
                  }

                  @media (min-width: 1024px) {
                    padding-bottom: 0 !important;
                  }

                  @media only screen and (max-width: 1023px) {
                    box-sizing: border-box;
                  }
                }

                #content-right-section {
                  height: 100%;
                  flex-shrink: 0;
                  position: relative;
                  z-index: 0;

                  #content-right-section-inner {
                    height: 100%;
                    width: ~"max(var(--upload-content-width, 0px), var(--items-info-content-width, 0px))";
                    transition: width 0.5s ease-in-out;

                    @media (max-width: 1023px) {
                      width: inherit;
                      transition: none;
                    }
                  }

                  #upload-section,
                  #items-info {
                    position: absolute;
                  }


                  #upload-section,
                  #upload-manager-container,
                  #items-info {
                    height: 100%;
                  }

                  @media (max-width: 1023px) {
                    max-height: none;
                    width: 100vw;
                    position: fixed !important;
                    z-index: 103;
                    transition: right 0.5s ease-in-out;
                    right: var(--items-info-content-right-mobile, "-100%");
                    bottom: 0;
                    height: calc(100dvh - 64px) !important;

                    #items-info {
                      width: 100%;
                    }
                  }
                }

                @media (min-width: 1024px) {
                  #fixedBottomArea {
                    position: absolute;
                    bottom: 3px;
                    right: 15px;
                  }
                }
              }
            }

            .cnt-mn {
              height: 100%;
              position: relative;
              outline: none;

              .filemanager,
              .trashpage,
              .trash_p,
              .timepage,
              .timemachine {
                height: 100%;
              }

              .audiopage,
              .sharetabs {
                margin-top: 0;
                height: 100%;

                &>div.tabcontent {
                  height: calc(100% - 48px);
                  min-height: auto;
                }

                &.tabs div.tabcontent {
                  margin: 0;
                }
              }

              .publinks {
                height: 100%;
              }

              .time-represent {
                overflow-y: auto;
                height: 100%;
              }
            }
          }

          .noleft {
            padding: 0;
            margin: 0;

            #content-right-section-inner {
              transition: none !important;
            }
          }
        }
      }
    }

    #root-datepicker {
      .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
        display: none;
      }
      .react-datepicker {
        border-radius: var(--spacing-xs);
        border: 1px solid rgba(0, 0, 0, 0.25);
        background: #FFF;
        box-sizing: border-box;
        padding: 2px 0;

        .react-datepicker__header {
          background: #fff;
          border: 0;
        }

        .react-datepicker__current-month {
          height: 40px;
          text-align: left;
          padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-xs) var(--spacing-lg);
          display: flex;
          align-items: center;
          justify-content: start;

          text-transform: capitalize;
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }

        .react-datepicker__day-name, .react-datepicker__day {
          width: 43px;
          height: 43px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;

          text-transform: capitalize;
          font-family: Roboto, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
        .react-datepicker__week, .react-datepicker__day-names {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .react-datepicker__month {
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4px;
        }
        .react-datepicker__day--outside-month {
          visibility: hidden;
        }
        .react-datepicker__day--keyboard-selected {
          background: var(--button-tetriary-background-default);
          color: #fff;
          font-weight: 500;
          border-radius: 100px;
        }
        .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
          border-radius: 0;
          background: var(--color-primary-100);
          color: #49454F;
        }
        .react-datepicker__day--range-start,
        .react-datepicker__day--range-end,
        .react-datepicker__day--selecting-range-start,
        .react-datepicker__day--selecting-range-end {
          position: relative;
          background: var(--button-tetriary-background-default);
          color: #fff;
          font-weight: 500;
          z-index:auto;
        }
        .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
          border-radius: 100px;
        }
        .react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start {
          border-radius: 100px 0 0 100px;
        }
        .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-end {
          border-radius: 0 100px 100px 0;
        }
        .react-datepicker__navigation {
          width: 40px;
          height: 40px;
          text-indent: initial;
          top: 14px;
          &:after {
            font-family: 'Font Awesome 6 Pro';
            font-weight: 600;
            font-size: 16px;
            color: #49454F;
            width: 16px;
            height: 16px;
          }
          &.react-datepicker__navigation--previous {
            left: auto;
            right: 54px;
            &:after {
              content: '\f053';
            }
          }
          &.react-datepicker__navigation--next {
            left: auto;
            right: 14px;
            &:after {
              content: '\f054';
            }
          }
          span {
            display: none;
          }
        }
      }
    }

    .input-styled {
      box-sizing: border-box;
      margin: 0;
      width: 100%;
      height: 40px;
      padding: var(--spacing-none) var(--spacing-xs) var(--spacing-none) var(--spacing-md);
      color: var(--text-primary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      border-radius: 88px;
      border: 1px solid var(--border-primary);
      background-color: var(--surface-secondary);
      box-shadow: none;

      &:focus {
        outline: none;
        border: 1px solid var(--color-primary-500);
      }

      &::-moz-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: var(--text-secondary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .szh-menu {
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: var(--radius-minimum);
    padding: var(--spacing-sm) 0;
    background-color: var(--color-base-white);
    z-index: 2000;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
  }

  .szh-menu__item {
    color: var(--text-primary);
    font-size: var(--base-font-size);
    line-height: 24px;
    padding: var(--spacing-xs) var(--spacing-md);

    .iconwrap {
      width: 20px;
      height: 20px;
      margin: 0;
      float: none;
      min-width: auto;
    }
  }

  .szh-menu__item--hover {
    background-color: var(--surface-hover);
  }

  .szh-menu__item--:active {
    background-color: var(--surface-hover);
  }

  .szh-menu__submenu>.szh-menu__item:after {
    content: "›";
    position: absolute;
    right: 1rem;
  }

  .szh-menu__divider {
    margin: var(--spacing-xs);
    background-color: var(--divider-secondary);
  }

  .szh-menu__item--disabled {
    .linkColorIcon {
      color: var(--button-primary-label-disabled);
    }
  }

  .tabs.audio-tabs,
  .tabs.newtabs {
    .ctrl {
      position: relative;
      z-index: 1;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: start;
      margin: 0;
      padding: 0;
      gap: var(--spacing-md);
      overflow: auto;
      border: none;
      height: 48px;
      color: var(--text-primary);
      font-size: var(--base-font-size);
      font-weight: normal;
      line-height: 24px;
      min-width: auto;

      @media only screen and (max-width: 1023px) {
        z-index: 0;
      }

      span {
        display: flex;
        padding: var(--spacing-sm) var(--spacing-md);
        align-items: center;
        justify-content: center;
        gap: var(--spacing-sm);
        border-radius: var(--radius-full);
        background-color: var(--surface-primary);
        cursor: pointer;
        transition: background-color 0.3s;
        white-space: nowrap;
        box-sizing: border-box;
        height: 40px;
        color: var(--text-primary);
        font-size: var(--base-font-size);
        border: 0;

        .icon-active,
        .icon-active::before,
        .icon-normal,
        .icon-normal::before {
          font-size: var(--medium-font-size);
          background: linear-gradient(rgb(21, 106, 115) 0%, rgb(0, 28, 31) 100%) text;
          -webkit-text-fill-color: transparent;
          text-shadow: none;
        }

        .icon-active {
          display: none;
        }

        &.sel,
        &:hover {
          background-color: var(--surface-primary);
          border: none;
          font-weight: normal;
          -moz-box-shadow: none;
          box-shadow: none !important;

          .icon-active {
            display: block;
          }

          .icon-normal {
            display: none;
          }
        }

        &.sel {
          background-color: var(--color-primary-100);
        }
      }
    }

    &.loading {
      .ctrl {
        pointer-events: none;

        // span:not(.sel) {
        //   opacity: 0.8;
        //   transition: opacity 200ms ease-in-out;
        // }
      }
    }
  }

  .tab-songs.tabcontent {
    .listGridWindow {
      .artist-col {
        max-width: 15% !important;
        min-width: 15% !important;
      }
    }
  }

  .tab-playlists.tabcontent {
    .listGridWindow {
      .artist-col {
        max-width: 20% !important;
        min-width: 20% !important;
      }
    }
  }
}

html.has-sticky {
  body {
    .megawrap {
      .midContain {
        .content {
          .main {
            #content-wrapper {
              #content-container {
                @media only screen and (min-width: 1024px) {
                  height: calc(100% - 112px);
                }

                @media only screen and (max-width: 1023px) {
                  #content-grid {
                    padding-bottom: 54px;
                    box-sizing: border-box;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.dark-appearance {
  // TO DO
}

html.page-login,
html.page-register {
  #content-top-bar {
    height: 0 !important;
    min-height: 0 !important;
  }
}

html.mobile-responsive {
  --right-icon-full-width: 18px;

  & .publicpage {
    overflow-x: auto;
    width: 100%;
  }

  .audiopage.tabs.audio-tabs div.tabcontent,
  .sharetabs.tabs.newtabs div.tabcontent {
    margin-top: var(--spacing-sm) !important;
  }

  .tabs.audio-tabs .ctrl,
  .tabs.newtabs .ctrl {
    min-width: auto;
    width: calc(100% - var(--spacing-sm));
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 var(--spacing-sm);
    height: 48px;
    align-items: flex-start;
    padding: 0 var(--spacing-sm);

    &>span {
      float: none;
      width: 200px;
      box-sizing: border-box;
      white-space: nowrap;
    }
  }

  .page-heading,
  .histpage h2,
  .revisionspage {
    padding: 0 var(--spacing-sm) 0 var(--spacing-sm);
  }

  & .tab-settings .tabcontent {
    overflow-y: auto;
    height: calc(100vh - 175px);
  }

  .most-trafficked-summary {
    padding: 0 var(--spacing-sm) 0 var(--spacing-sm);

    h4 {
      margin: 0;
    }
  }

  .gridlist {
    th {
      background: var(--button-secondary-background-default);
      padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-sm);
      border-top: none;
      height: 20px;

      color: var(--text-primary);
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      span.sort {
        margin: var(--spacing-xs) var(--spacing-sm) 0 var(--spacing-sm);
      }
    }

    tr {
      border-bottom: 1px solid var(--divider-secondary);

      .mlnk {
        .iconwrap {
          min-width: auto;
          margin-left: var(--spacing-sm);

          img {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }

    td {
      &:not(.actions) {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.mobshr {
          width: 100%;

          .sub-title {
            padding-left: 38px;
          }
        }
      }

      &.letterhead {
        border: none;
        box-shadow: none;
        background: #F0F4F5;
      }

      span.filename {
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 400;
      }

      span.sub-title {
        font-size: 11px;
        font-weight: 400;
        box-sizing: border-box;
        margin-top: 5px;
      }
    }
  }

  .cv-tooltip:not(.visible-on-mobile) {
    display: none !important;
  }

  --small-font-size: var(--font-size-13);
  --base-font-size: var(--font-size-16);
  --medium-font-size: var(--font-size-18);
}

html.no-topbar {
  &.noleft {
    .content .left.lnav {
      display: none;
    }
  }

  body {
    .megawrap {
      .midContain {
        .content {
          .main {
            #content-wrapper {
              height: 100%;

              #content-top-bar {
                display: none;
              }

              #content-container {
                height: 100%;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

.g-modal {
  display: inline-block;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  margin: 50px 10px;

  .g-modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 14px;
    height: 14px;
  }

  .g-modal-title {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
    background: none;
    border: none;
    color: inherit;
    height: auto;
    line-height: normal;
    padding-left: 0;

    .g-modal-title-text {
      margin: 0;
    }
  }

  .g-modal-inner {
    width: 480px;
    border-radius: 21px;
    background-color: #fff;
    padding: 30px;
    box-sizing: border-box;
    transition: all 250ms ease-in-out;

    @media (max-width: 500px) {
      width: 90vw;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* File icons loading animation */
.iconwrap.loading-spin-animation {
  img {
    animation: spin 2s linear infinite;
  }
}

.thumbready,
.thumbfailed,
.iconready {
  .iconwrap.loading-spin-animation {
    img {
      animation: unset;
    }
  }
}