@import "elements.less";
@import "colors.less";

.fa {
    .bheading, table.adminsettings th, .viewasbar {
        font-family: "Roboto", sans-serif !important;
    }
}

.clearfix-after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.button.disabled:hover {
    box-shadow: none;
}

p { margin: 0; }

.bform {
    input[type="text"], input[type="password"], input[type="email"] {
        height: 34px;
        width: 100%;
        border: 1px solid @binput-border-color;
        background-color: @binput-background-color;
        box-shadow: inset 1px 2px 1px rgba(0,0,0,0.05);
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;

        &:disabled {
            background-color:@gray-light;
            border-color:@gray-light;
        }
    }
    a {
        color: #444;
        font-size: 15px;
    }
}

.form-2-cols {
    .col-left, .col-right {
        display: block;
        float: left;
        width: 290px;
        margin-bottom: 16px;
        box-sizing: border-box;
    }
    @media screen {
        @media (max-width: 620px) {
            .col-left, .col-right {
                width: 100%;
            }
        }
    }
    .col-left {
        margin-right: 20px;
    }
    .col-right:after {
      content: ".";
      clear: both;
      display: block;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
    .row {
        float: left;
        width: 100%;
        margin-bottom: 16px;
        &.action { text-align: center }
    }
    .col-textonly {
        padding-top: 30px;
    }
}

label.disabled {
    color: @gray-dark;
}
.warning {
    margin: 13px 0;
    background-color: #fff;
    padding: 10px;
    text-align: center;
    border: 1px solid #FC9381;
    .rounded(3px);
    font-size: 11px;
    color: #FA5F44;
}

.col-md-1 { width: 8.333333333% }
.col-md-2 { width: 16.66666667% }
.col-md-3 { width: 25% }
.col-md-4 { width: 33.33333333% }
.col-md-5 { width: 41.66666667% }
.col-md-6 { width: 50%; }
.col-md-7 { width: 58.33333333% }
.col-md-8 { width: 66.6666667% }
.col-md-9 { width: 75% }
.col-md-10 { width: 83.33333333% }
.col-md-11 { width: 91.66666667% }
.col-md-12 { width: 100% }

.col-sep {
    float: left;
    width: 100%;
    color: #aaa;
    text-align: center;
    font-weight: 700;
    font-size: 13px;
    padding: 5px 0;
    margin: 10px 0 0 0;
}

.note-box {
    margin: 20px;
    height: 34px;
    background-color: #FFFFCC;
    border: 1px solid #E5E5B7;
    line-height: 34px;
    display: inline-block;
}

/*
    business-accounts is added to all business accounts
    put under it classes that should work in both the business admin and the file manager
*/
.business-account {
    .baccount-tabs {
        width: 780px;
        box-sizing: border-box;
    }

    .gfrespace, .getmorespace, .usedbar {
        display: none!important;
    }

    .spacen {
        height: 24px;
        line-height: 24px;
        /* padding: 0; */
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .profile .username {
        text-decoration: underline;
        cursor: pointer;
    }

    /* all commments */
    tr.comments {
        background: #f4f4f4;

        &.hover {
            td {
                background: #f4f4f4;
            }
        }

        td {
            border-left: none;
            border-right: none;
            background: #f4f4f4 !important;

            &:hover {
                background: #f4f4f4 !important;
            }

            &.commentstd {
                padding: 0 0 5px 0;

                .commentslist {
                    overflow-y: auto;
                    max-height: 400px;
                    padding-right: 5px;

                    .revisionsbetween {
                        font-size: 12px;
                        font-style: italic;
                        color: #999;
                        line-height: 36px;
                        height: 30px;
                        text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                    }
                }
            }
        }

        .addcomment {

            .cmtitle {
                text-align: center;
                font-weight: bold;
                line-height: 36px;
            }

            textarea {
                width: 700px;
                height: 80px;
                padding: 5px;
                display: block;
                margin: 0 auto 16px auto;
            }

            .cmt-butt-area {
                padding-bottom: 16px;
                text-align: center;
                .button {
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                    text-transform: initial;
                }
            }
        }

        .comment {
            background: #ffffff;
            margin: 6px 0 6px 0;
            border-left: 2px solid #d9d9d9;
            box-shadow: 0px 1px 0px rgba(0,0,0,0.05);
            padding: 10px;

            &:first-child {
                margin-top: 0;
            }

            .commhead {
                .userinfo {
                    float: left;
                    font-weight: bold;

                    img {
                        margin-right: 10px;
                    }
                    a {
                        text-decoration: none;
                        &.true {
                            color: #262728;
                        }
                        &.false {
                            color: #666666;
                            cursor: default;
                            span {
                                text-decoration: none;
                            }
                        }
                    }
                    span {
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .commentinfo {
                    float: right;
                    color: #999;
                    line-height: 24px;
                    text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
                    font-size: 13px;

                    img {
                        margin-left: 5px;
                        margin-top: -2px;
                        cursor: pointer;
                        display: none;
                    }

                    a {
                        color: #333;
                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                }
            }

            .commenttxt {
                margin: 10px 0 0 0;

                a {
                    color: #3b73af;
                }
            }

            &.isauthor .commentinfo img { display: inline-block; }
            &:hover {
                background: #FFFFD7;
            }
        }

        .deletedcomment {
            background: #fafafa;
            line-height: 20px;
            padding: 10px;
            font-size: 13px;
            border-radius: 2px;
            box-shadow: 0px 1px 0px rgba(0,0,0,0.05);
            border-left: 2px solid #f7f7f7;
            font-style: italic;
            color: #999;

            margin: 6px 0 6px 0;
        }

        .commentsinfo {
            line-height: 40px;
            .showing {
                float: left;
                color: #999;
                font-style: italic;
            }

            .loadmore {
                float: right;
                font-weight: bold;
                cursor: pointer;
            }
        }

        &.loading .commentslist { display: none; }
        &.loading .addcomment { display: none; }
    }

    .gridline.opencomments td {
        background: #f4f4f4;
    }

    .sharetabs {
        /*
        th.actions {
            width: 200px !important;
        }
        */
        td.actions {
            text-align: center;
        }
    }
}

.businessmodal {
    &.modal {
        border-radius: 5px, 5px, 5px, 5px;

        .modal-title {
            padding: 0;
            line-height: 46px;
            height: 46px;
            font-size: 13px;
            text-align: center;
            font-weight: bold;
            background: #f4f4f4;
            border-radius: 5px, 0, 0, 5px;
            border-bottom: 1px solid #f0f0f0;
            color: #000;

            .modal-title-text {
                max-width: 100%;
                margin-right: 0px;
                /*text-indent: -10px;*/
            }
        }

        .butt-area, .modal-content {
            border-radius: 0, 5px, 5px, 0;
            border-top: 1px solid #e9e9e9;
            background: #fff;

            &.nobord {
                border-top: none;
            }
        }

        .modal-close {
            display: none !important;
        }

        label {
            font-size: 13px;
            line-height: 20px;
            vertical-align: middle;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.businesstabs {
    display: flex;
    // align-items: center;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
    width: 780px;
}

.business {
    // a {
    //     color:#242526;
    //     text-decoration: underline;
    //     &:hover { text-decoration: none; }
    // }

    a.button {
        text-decoration: none;
        border-radius: 5px;
        float: right;
    }

    span.team, span.username {
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    span.team.team-everyone {
        font-weight: normal;
        text-decoration: none;
        cursor: initial;
    }

    tr.teaminactive td {
        color: #999;
    }

    td.tstatus {
        width: 75px;
    }
    td.teamact {
        width: 100px;
        padding: 0 10px;
    }

    td.teamact.teamusers {
        width: 200px;
    }

    .invitedacts {
        display: inline-block;
        margin-left: 5px;
    }

    .invitedacts img {
        vertical-align: middle;
        margin: -1px 2px 0 2px;
        cursor: pointer;
    }

    .teamacts {
        width: 76px;
        margin: 0 auto;

        display: inline-block;
        width: auto;

        padding-top: 1px;
    }
    .teamacts img {
        /*float: left;*/
        margin-right: 10px;

        margin-right: 5px;
        margin-left: 5px;

        cursor: pointer;
    }

    .teamacts .teamonoff {
        margin-left: 5px;
        margin-right: 5px;
    }

    .teamacts img:hover {
        opacity: 0.8;
    }

    td.statusact { color: #090; font-weight: bold; }
    td.statusina, tr.teaminactive td.statusina { color: #C30; font-weight: bold; }

    .teamonoff {
        display: inline-block;
        width: 18px;
        height: 18px;
        /*float: left;*/
    }
    .teamonoff img {
        margin-right: 0 !important;
    }
    .teamonoff .dea, .teamonoff.teamact .act {
        display: none;
    }
    .teamonoff .act, .teamonoff.teamact .dea {
        display: inline-block;
    }

    .crteam, .invusers {

        display: none;

        float: right;

        width: 168px;
        height: 34px;
        font-size: 12px;
        font-weight: bold;
        text-indent: 43px;
        line-height: 34px;
        color: #fff;
        text-decoration: none;
        border: 5px solid rgb(133,198,0);

        text-shadow: 0px 1px 0px rgba(0,0,0,0.02);

        box-shadow: 0 1px 1px rgba(0,0,0,0.07);

        background-repeat: no-repeat;
        background-position: left center;
        @mixin retina "/ZCDNURL/img/b/add-team.png";
        background-size: 46px 18px;
        padding-left: 15px;
    }

    .invusers {
        border: none;
        background-repeat: no-repeat;
        background-position: left center;
        @mixin retina "/ZCDNURL/img/b/add-user.png";
        background-size: 30px 18px;
        background-color: rgb(136,202,0);
    }
    .invusers.inactive {
        border: none;
        background-repeat: no-repeat;
        background-position: left center;
        @mixin retina "/ZCDNURL/img/b/add-user.png";
        background-size: 30px 18px;
        background-color: rgb(210,210,210);
        cursor: inherit;
    }

    ul.foldertype {

        margin: 10px 0 10px 0;
        padding: 0;

        li {
            width: 230px;
            float: left;
            line-height: 20px;
            list-style: none;

            label {
                padding: 0;
                font-weight: normal;
                cursor: pointer;
                input {
                    margin: -2px 5px 0 0;
                    vertical-align: middle;
                }
            }
        }
    }

    .teamsearch {
        float: right;
        margin-right: 8px;

        input {
            font-size: 12px;
            color: #bbb;
            padding-left: 8px;
            border: none;
            background: #fff url(/img/b/gridsearch.png) no-repeat right center;
            outline: none;
            width: 297px;
            height: 26px;

            border: 1px solid rgb(204,204,204);

            .rounded(1px);

            vertical-align: middle;
            margin-top: -2px;
        }
    }
    .bteams, .busers, .bteam, .blogs {
        .cheader {
            margin: 0;
            height: inherit;
        }

        td.file, th.name {
            padding-left: 10px;
        }

        th.head {
            max-width: 200px;
            width: 200px;
        }
    }

    .teamscnt {
        font-size: 17px;
        font-weight: normal;
    }

    .headerunder {
        .bustitle {
            padding-left: 5px;
        }
    }

    .bustitle {
        font-size: 11px;
        color: #262728;
        text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
        font-weight: bold;
    }

    .combo-contain {
        padding: 7px 7px 0px 7px;
        border: 1px solid #ccc;
        box-shadow: inset 1px 2px 1px rgba(0,0,0,0.05);
        background: #f9f9f9;
    }

    .combo-res {
        margin-bottom: 7px;
    }

    .combo-wrap input {
        background: #f9f9f9 !important;
    }

    .combo-contain.focu input {
        background: #FFFFE8 !important;
    }

    .combo-wrap input {
        height: 22px;
        line-height: 20px;
        margin-bottom: 2px;
    }


    /* invite users */

    .achtung {

        display: none;

        background: rgb(255,248,223);
        width: 526px;

        padding: 12px 15px 13px 15px;

        border: 1px solid rgb(250,243,219);
        text-align: center;
        line-height: 18px;
        font-size: 13px;

        margin: 0 0 20px 0;

        .attention {
            color: #FF8000;
        }
        .attention_message {

        }
    }

    .account_info {
        display: none;
        float: right;
        a {
            margin-left: 5px;
        }
    }

    .usersdeactivated {
        color: red;
    }

    .invmails {
        width: 540px;
        border: 1px solid #ccc;
        background: #f9f9f9;
        box-shadow: inset 1px 2px 1px rgba(0,0,0,0.05);
        padding: 0 10px 0 10px;
        height: 19px;
    }

    .invmails:focus {
        outline: none;
    }

    .modal label span {
        font-weight: normal;
    }

    .invmessage {
        width: 540px;
        height: 80px;
        border: 1px solid #ccc;
        background: #f9f9f9;
        box-shadow: inset 1px 2px 1px rgba(0,0,0,0.05);
        padding: 7px;
    }

    .invmessage:focus {
        border: 1px solid #999;
        background: #FFFFea;
        outline: none;
    }

    .modal {
        .teams label {
            font-weight: normal;
            padding: 0;
            line-height: 19px;
            font-size: 12px;
            margin: 0 15px 10px 0;
            cursor: pointer;

            width: 129px;
            width: 160px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        .teams label input {
            margin: 0 7px auto 2px;
            vertical-align: middle;
        }

        .teams {
            margin-bottom: 10px;
            max-height: 240px;
            overflow-y: auto;
        }
    }

    .invitemailstatic, .teamsstatic {
        display: none;
    }

    .userfilters {
        float: left;
        padding-left: 5px;
    }

    .userfilters .bustitle {
        color: #B6B6B6;
        /*color: #555;*/
        margin-right: 20px;
    }

    .filter_perm, .filter_teams {
        width: 187px;
        display: inline-block;
        margin-right: 10px;
    }

    .filter_status {
        display: inline-block;
        font-size: 12px;
        color: #555;
        font-weight: normal;
        cursor: pointer;
        padding-bottom: 0;
        margin-left: 3px;

        input {
            vertical-align: middle;
            margin-right: 4px;
        }

        &.teams {
            float: right;
            margin-right: 15px;
        }
    }

    .link {
        input.intive-link {
            width: 100%;
            height: 32px;
            font-size: 13px;
            padding: 0 5px;
            border: 1px solid rgb(202,202,202);
            box-shadow: inset 0px 3px 0 0 rgba(0,0,0,0.03);
            background-color: #fafafa;
            box-sizing: border-box;
        }
    }

    th.lastactivity {
        width: 155px;
    }

    th.srorage {
        width: 110px;


        .orange {
            color: #ff9900;
        }

        .red {
            color: #e92800;
        }

        .underline {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    th.teams {
        min-width: 100px;
    }

    td.storage {
        .orange {
            color: #ff9900;
        }

        .red {
            color: #e92800;
        }

        .total-quota {
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .bheading {
        width: 780px;
        height: 27px;
        background: #f0f0f0;
        box-sizing: border-box;

        border-top: 1px solid #E9E9E9;
        border-bottom: 1px solid #E9E9E9;
        font-family: "Roboto", sans-serif;
        font-size: 11px;
        font-weight: bold;
        color: #AEAEAE;
        padding-left: 10px;
        text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
        zoom: 1;
        line-height: 26px;
    }

    .userinfo table {
        margin: 0;
        width: 780px;

        .storage {
            .orange {
                color: #ff9900;
            }
    
            .red {
                color: #e92800;
            }
    
            .total-quota {
                font-weight: bold;
                display: inline-block;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .userinfo .actions {
        position: absolute;
        top: 10px;
        right: 2px;
        z-index: 1000;
        cursor: pointer;
        
        img {
            margin-left: 10px;
        }
    }

    td.tdimage {
        width: 128px;
    }

    .userimage {
        margin-right: 20px;
        margin: auto;
    }

    td.lft, td.rght {
        padding: 5px 0 5px 0;
        line-height: 18px;
        font-size: 12px;
        text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
        border-bottom: 1px solid #e9e9e9;
    }

    tr:last-child td.lft, tr:last-child td.rght {
        border-bottom: none;
    }

    td.lft strong, td.rght strong {
        display:  block;
    }

    td.lft {
        text-align: left;
        width: 285px;
    }

    td.rght {
        text-align: right;
      width: 1%;
      white-space: nowrap;
    }

    td.rght.actions img {
        margin-left: 7px;
        cursor: pointer;
        opacity: 1;
    }

    td.rght.actions img:hover {
        opacity: 0.7;
    }

    .useractive .userina, .useract {
        display: inline-block;
    }

    .useractive .useract, .userina {
        display: none;
    }

    .userimage {
        position: relative;
        width: 128px;
    }

    .userimage .avatar {
        position: absolute;
        top: 0;
        right: 0;
        width: 21px;
        height: 20px;
        overflow: hidden;

        /*border: 1px solid #000;*/

        cursor: pointer;
        background-size: 21px 20px;
        border: none;
        background-repeat: no-repeat;
        background-position: top right;
        @mixin retina "/ZCDNURL/img/b/molivche-icon-20x20.png";
        opacity: 0.5;
    }
    .userimage .avatar:hover {
        background-color: rgba(0, 0, 0, .7);
        border-radius: 100%;
    }

    .userimage .avatar input {
        opacity: 0;
        height: 300px;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: inherit;
        font-size: 1000px !important;
        width: auto !important;
        margin: 0;
    }

    .userimage .progress {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.75);

        .percent {
            color: #fff;
            font-size: 24px;
            line-height: 128px;
            text-align: center;
        }

        .bar {
            background: rgba(0,0,0,0.5);
            height: 3px;
            position: absolute;
            bottom: 0;
            width: 100%;

            .fill {
                background: #88ca00;
                height: 3px;
                width: 0;
            }
        }
    }

    .teamtbl {
        margin-left: 5px;
        width: 715px;
    }

    .teamtbl, .teamtbl td {
        border-collapse: collapse;
    }

    .teamtbl tr {
        margin: 0;
    }

    .teamtbl td.star {
        width: 31px;
        img {
            margin-right: 10px;
            margin-top: -1px;
            margin-left: 5px;

            &.active {
                opacity: 0.7;
                cursor: pointer;
                &.active:hover {
                    opacity: 1;
                }
            }
        }
    }

    .teamtbl td.userteamacts {
        min-width: 60px;
        text-align: right;
        img {
            margin-left: 7px;
            cursor: pointer;
        }
    }

    .teamtbl td.teamname {
        min-width: 200px;

        .inact {
            color: #999;
        }
    }

    .teamtbl tr td {
        border-bottom: 1px solid #e9e9e9;
        margin: 0;
        line-height: 34px;
        font-size: 12px;
        text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
    }

    .teamtbl td.teamsbutton {
        text-align: center;
        border-bottom: none;
        padding-top:  10px;
        padding-bottom: 10px;
    }

    span.shseton { color: green; font-weight: bold; }
    span.shsetoff { color: red; font-weight: bold; }

    table.adminsettings {

        border-collapse: collapse;
        margin-top: -29px;
        width: 780px;

        th {

            height: 27px;
            background: #f0f0f0;

            font-family: "Roboto", sans-serif;
            font-size: 11px;
            font-weight: bold;
            color: #AEAEAE;
            text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
            zoom: 1;
            line-height: 26px;

            text-align: left;

            width: 60px;

            &.blank {
                width: 540px;
            }

            .defaultexplain {
                color: #000;
                cursor: pointer;
                font-size: 12px;
                margin-left: 2px;
            }

            &.setting {
                text-align: right;
                text-indent: 12px;
                direction: rtl;
            }
        }

        td {
            vertical-align: top;
            text-align: center;

            border-bottom: 1px solid #e9e9e9;

            height: 40px;
            line-height: 40px;

            label {
                display: block;
                cursor: pointer;
                width: 100%;

                padding: 0;

                &:hover {
                    background: #eee;
                }

                input {
                    margin: 0;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-top: 9px;
                }

                &.on, &.off {
                    border-radius: 15px;
                    line-height: 27px;
                    height: 25px;
                    font-size: 11px;
                    margin-top: 3px;

                    text-align: left;

                    /*padding-left: 7px;*/
                    input {
                        margin-left: 7px;
                        margin-top: -1px;
                        margin-right: 7px;
                    }
                }

                &.on {
                    background: #88ca00 !important;
                    color: #fff;
                    text-shadow: #7dba00 0 1px 0;
                    /*border: 1px solid #85c600;*/
                }

                &.off {
                    background: #ec4211 !important;
                    color: #fff;
                    text-shadow: #ce360a 0 1px 0;
                    /*border: 1px solid #ec4211;*/
                }
            }

            &.name {
                text-align: left;
                padding-left: 10px;
                line-height: 40px;
                height: 40px;
                width: 582px;

                div.ptr {
                    cursor: pointer;
                }

                .settingname {
                    font-size: 12px;
                    font-weight: bold;
                    cursor: pointer;
                }

                .settingexplain {
                    display: none;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    color: #3d3d3d;
                    arial: 'Arial MT';
                    display: none;
                    margin: 0px 0 6px 2px;
                }

                img {
                    vertical-align: middle;
                    margin-top: 0px;
                    margin-right: 3px;
                }

                .arrowdown {
                    display: none;
                }
                .arrowright {
                    display: inline-block;
                }

                &.visbl {
                    .settingexplain {
                        display: block;
                    }
                    .arrowdown {
                        display: inline-block;
                    }
                    .arrowright {
                        display: none;
                    }
                }
            }

            &.setting_choose {
                padding: 0 10px 0 0px;
                min-width: 60px;
                text-align: right;
                white-space: nowrap;
                font-size: 12px;

                .setting_options {
                    /*
                    &.positive {
                        color: #86C600;
                    }

                    &.negative {
                        color: #FC354C;
                    }

                    &.neutral {
                        color: #333;
                    }
                    */
                }

                .setting_value {
                    display: none;

                    &.positive {
                        color: green;
                    }

                    &.negative {
                        color: red;
                    }

                    &.neutral {
                        color: #333;
                    }
                }
            }

            &.setting_color {
                width: 2px;
                opacity: 0.5;
                padding: 0;

                &.default {
                    background: #ddd;
                }

                &.positive {
                    background: #86C600;
                    opacity: 0.8;
                }

                &.negative {
                    background: #FC354C;
                }

                &.neutral {
                    background: #333;
                }
            }

            &.setting_options {
                min-width: 50px;
                white-space: nowrap;
                text-align: left;
                padding-right: 10px;

                .single_choice {
                    line-height: 26px;
                    height: 26px;
                    vertical-align: middle;
                    display: inline-block;
                }

                &.positive {
                    color: green;
                }

                &.negative {
                    color: red;
                }

                &.neutral {
                    color: #333;
                }
            }

            &.setting_value {
                font-size: 12px;
                padding-left: 10px;
                padding-right: 10px;
                white-space: nowrap;
                text-align: left;
                border-right: 1px solid #000;
                width: 100px;

                &.positive {
                    color: green;
                }

                &.negative {
                    color: red;
                }

                &.neutral {
                    color: #333;
                }
            }
        }

        .effective {
            margin-left: 4px;
        }

        .effective {
            display: none;
        }

        &.no-inherit {

            th.effective_value {
                display: none;
            }

            th {
                .effective {
                    display: none;
                }
            }

            td {
                &.name {

                }

                &.setting_options {
                    width: 220px;
                    border-right: 1px solid #000;
                }
            }
        }
    }

    .teamsettings_buttonarea, .usersettings_buttonarea, .accountsettings_buttonarea {
        width: 780px;
        padding: 15px 0 15px 0;
        text-align: center;
    }

    .accesssettings_explain {
        width: 753px;
        font-size: 12px;
        text-align: right;
    }

    .unaccessible td label:hover {
        background: #fff !important;
    }

    td.star img.active {
        cursor: pointer;
    }

    h2 .status.active {
        color: #090;
    }

    h2 .status.inactive {
        color: red;
        border: none;
    }

    h2 .acts {
        .teamonoff {
            float: none;
            margin-left: 7px;
            cursor: pointer;
            vertical-align: middle;

            width: auto;
            height: auto;

            img {
                margin-top: -3px;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .edit {
            cursor: pointer;
            margin-left: 7px;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .bteam {
        .adminsettings th.blank {
            width: 600px;
        }

        /*
        tr {
            td {
                border-bottom: 1px solid #e9e9e9;
            }
        }
        */

        td.teamact.teamusers {
            width: 90px;

            .teamacts {
                max-width: 50px;

                /*max-width: 100px;*/

                img:last-child {
                    margin-right: 0;
                }
            }
        }

        .headerunder {
            border-top: none;
        }
    }

    .logheader {
        /*width: 100%;*/
        padding-right: 5px;

        height: 46px;
        line-height: 46px;
        background: #f9f9f9;
        color: #B6B6B6;
        padding-left: 10px;
        border-top: 1px solid #E9E9E9;
        font-size: 15px;
        font-weight: normal;
        z-index: 2000;

        .filter_logtypes {
            width: 270px;
            float: right;
            margin-top: 7px;
            margin-left: 10px;
        }

        .filter_title {
            float: right;
            color: #262728;
            font-size: 11px;
            font-weight: 700;
            margin-left: 10px;
        }

        .filter_entity {
            float: right;
            width: 300px;
            margin-top: 7px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .filter_dt {
            // float: right;
            /*width: 200px;*/
            margin-top: 0;
            color: #262728;
            font-size: 11px;
            font-weight: 700;
            margin-right: 0px;

            input {
                vertical-align: middle;
                padding: 2px 21px 2px 4px;
                width: 68px;
                margin-left: 5px;
                margin-right: 10px;
                margin-top: -2px;
                height: 24px;

                background:rgb(255,255,255);
                border: 1px solid rgb(168,168,168);
                border-radius: 1px;
                box-shadow: inset 0px 2px 2px rgba(0,0,0,0.05);

                background: url('/img/b/callendar-inside.png') no-repeat right 4px bottom 50%;
            }
        }

        .filter_go {
            float: right;
            height: 29px;
            line-height: 29px;
            margin-top: 7px;
            margin-left: 10px;
            width: 120px;
            margin-right: 3px;
        }
    }

    th.event {
        width: 200px;
    }

    .gridlist td.histdata > a {
        float: initial;
    }

    .objdataholder {
        line-height: 24px;
        padding: 8px 0 8px 0;
    }

    .blogs .gridlist td {
        vertical-align: top;
        line-height: 36px;
    }

    .blogs div.iconwrap.folder {
        width: 20px;
        height: 20px;
        min-width: initial;
    }

    .blogs div.iconwrap {
        min-width: initial;
    }

    .textf:extends(.binput){}
    .tarea{
       background-color: @binput-background-color;
       box-shadow: inset 1px 2px 1px rgba(0,0,0,0.05);
    }

    .table {
        border-spacing: 0;
        box-sizing: border-box;
        &.medium {
            width: 780px;
        }
        thead {
            th {
                line-height: 26px;
                padding: 0 0 0 10px;
                background-color: @gray-light1;
                border-top: 1px solid @gray-medium;
                border-bottom: 1px solid @gray-medium;
                text-align: left;
                font-size: 11px;
                font-weight: bold;
                color: @gray-medium1;
                text-shadow: 0px 1px 0px rgba(255,255,255,0.5);
            }
        }
        td.border-left {
            border-left: 1px solid @gray-medium;
        }
        td.border-right {
            border-right: 1px solid @gray-medium;
        }
        td {
            padding: 0 0 0 10px;
            font-size: 12px;
            line-height: 33px;
            border-bottom: 1px solid @gray-medium;


            .billing-invoice-msg {
                font-size: 13px;
                font-weight: 500;
            }
        }
        tr {
            &.noborder td {
                border-bottom: none;
            }
        }
        .row-heading {
            font-weight: bold;
        }
        tfoot {
            tr {
                td {
                    padding: 20px 0;
                    text-align: center;
                }
            }
        }
    }

    .button-update-invoice-info {
        display: inline-block;
    }

    .button-add-billing-info {
        display: none;
    }

    .billing-history-invoice {
        margin-bottom: 20px;
        width: 780px;
    }

    .share-settings {
        text-align: center;
        img { margin-bottom: 5px}
        td {
            padding: 20px 0;
            vertical-align: top;
        }
        .form-vertical {
             margin: 5px 0 10px 20px;
        }
        .form-vertical label {
            line-height: 21px;
			min-height: 21px;
            height: auto;
            font-weight: normal;
            text-align: left;
            margin-left: 10px;
            font-size: 12px;
            cursor: pointer;
            input {
                margin: 0 5px 0 0;
                vertical-align: middle;
            }
        }
        strong { display: block; text-align: center; }
        p {
            line-height: 22px;
            padding: 0 5px
        }
        .btn-group {
            margin: 10px 0;
        }
    }

    .form-update-company-info, .form-update-invoice-info, .modal-form {
        margin-top: 20px;
        button { width: 150px!important; line-height: 38px; padding:0; }
        .butt-area {margin-top: 10px; padding-bottom: 20px; border-top: 1px solid @gray-light1;}
        .tarea {
            width: 373px;
            vertical-align: top;
            height: 52px;
            margin-right: 20px;
        }
        label.tarealabel {
            margin-top: 10px;
        }
    }

    .business-plan-upgrade{

        padding: 0 9px 10px 20px;
        width: 500px;
        box-sizing: border-box;
        &:after {
          content: ".";
          clear: both;
          display: block;
          visibility: hidden;
          line-height: 0;
          height: 0;
        }

        input[type="number"] {
            box-sizing: border-box;
            width: 100%;
            height: 36px;
        }
        .title {
            font-weight: bold;
            margin-bottom: 4px;
            padding-bottom: 0;
            color: #aaa;
            font-size: 13px;
        }
        .selectable {
            height: 36px;
            line-height: 36px;
            width: 100%;
            box-sizing: border-box;

            cursor: pointer;

            text-align: center;
            background-color: #f9f9f9;
            border: 1px solid #ccc;

            input {
                float: left;
                margin: 11px -11px 0 10px
            }
            &.active span {
                font-weight: bold;
            }
        }

        div {
            box-sizing: border-box;
            float:left;
            padding-right: 11px;
        }
    }

    .deactuser {

        width: 100%;

        tr {

            &:first-child {
                margin-bottom: 10px;

                td {
                    border-bottom: 1px solid #e9e9e9;
                }
            }

            td {

                padding: 20px 20px 20px 10px;

                label {
                    cursor: pointer;
                    font-weight: normal;
                    margin: 0;
                    padding: 0;

                    color: #777;
                    line-height: 18px;
                    font-size: 12px;

                    strong {
                        display: block;
                        font-size: 15px;
                        color: #232425;
                        margin-bottom: 4px;
                    }
                }

                &:first-child {
                    vertical-align: middle;
                    padding: 20px 10px 20px 20px;
                    width: 20px;
                    text-align: center;
                }
            }
        }
    }
}

.del_bs_acc_warn {
	background-color: #fff0f2;
	border: 1px solid #fd7384;
	color: #fc354c;
	font-size: 1.25em;
	margin: 27px;
	padding: 38px 43px;
	text-align: center;
}
.del_bs_acc_warn strong:first-child {
	display: block;
	font-size: 1.17em;
	margin-bottom: -8px;
}

.logo-placeholder {
    min-width: 120px;
    width: 180px;
    height: 50px;
    margin: 5px 0 5px 0;
    background: url('/ZCDNURL/img/checkered-background.gif');
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);

    img {
        vertical-align: top;
    }
}

span.bgcolor {
    font-weight: bold;
    font-family: monospace;
}

input.bgcolor {
    width: 45px !important;
    margin-right: 10px;

    &.is-color {
        height: 24px;
        width: 80px;
        padding: 0 2px 0 2px;
        vertical-align: top;
    }
}

.branding .bheading {
    margin-bottom: 2px;
}

.explain {
    margin: 1px 0 4px 8px;
    line-height: 19px;
}

.logo-placeholder {
    position: relative;

    .branding-logo-image {
        display: none;
    }

    .delete {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;

        background: rgba(255, 0, 0, .5) url('/ZCDNURL/img/b/logo-delete.png') no-repeat center center;

        &:hover {
            background-color: rgba(255, 0, 0, .7);
        }
    }

    .upload {
        position: absolute;
        top: 0;
        right: 0px;
        width: 21px;
        height: 20px;
        overflow: hidden;

        /*border: 1px solid #000;*/

        cursor: pointer;
        background: rgba(0, 0, 0, .5) url('/img/b/molivche-icon-20x20.png') no-repeat top right;

        input {
            opacity: 0;
            height: 300px;
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: inherit;
            font-size: 1000px !important;
            width: auto !important;
            margin: 0;
        }

        &:hover {
            background-color: rgba(0, 0, 0, .7);
        }
    }

    &.has-logo {
        .delete {
            display: block;
        }

        .upload {
            right: 22px;
        }

        .branding-logo-image {
            display: inline-block;
        }
    }

    .progress {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);

        .percent {
            color: #fff;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
        }

        .bar {
            background: rgba(0, 0, 0, 0.5);
            height: 3px;
            position: absolute;
            bottom: 0;
            width: 100%;

            .fill {
                background: #88ca00;
                height: 3px;
                width: 0;
            }
        }
    }
}

.settings_explain_div {
    line-height: 20px;
    padding: 13px 0 13px 7px;
    font-size: 12px;
    width: 780px;
    text-align: left;

    &:first-child {
        padding-top: 0;
    }

    &.just-title {
        padding-bottom: 3px;
    }

    h3 {
        font-size: 17px;
        margin: 0;
        line-height: 33px;
    }
}
